import { AppState, ScreenStateKind } from "../state";
import { isEmailObviouslyMalformed } from "./emailAddress";
import { MIN_PASSWORD_LENGTH } from "./password";

export interface LoginInfoProblems {
  obviouslyMalformedEmailAddress: boolean;
  invalidPassword: boolean;
}

export function getObviousLoginInfoProblems(
  state: AppState
): LoginInfoProblems {
  const problems: LoginInfoProblems = {
    obviouslyMalformedEmailAddress: false,
    invalidPassword: false,
  };

  const { screen } = state;
  if (screen.kind !== ScreenStateKind.Login) {
    return problems;
  }

  const { email } = screen;
  if (
    isEmailObviouslyMalformed(email) ||
    state.malformedEmailAddresses.includes(email)
  ) {
    problems.obviouslyMalformedEmailAddress = true;
  }

  if (screen.password.length < MIN_PASSWORD_LENGTH) {
    problems.invalidPassword = true;
  }

  return problems;
}

export function hasAnyProblem(problems: LoginInfoProblems): boolean {
  return problems.obviouslyMalformedEmailAddress || problems.invalidPassword;
}
