// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getApiKeySegmentFromSeed } from "./obfuscation";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Minor obfuscation to make things a tad harder for dumb bots.
// This is not a proper security measure, just a minor inconvenience.
// Smarter bots will see through this.
const obfuscationPart1 = "dRiiPxm1hb047nNGvamtuIm85nV5GAySazIA";
const obfuscationPart2 = getApiKeySegmentFromSeed(Math.random());

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: obfuscationPart1.split("").reverse().join("") + obfuscationPart2,
  authDomain: "zaqlo-main.firebaseapp.com",
  projectId: "zaqlo-main",
  storageBucket: "zaqlo-main.appspot.com",
  messagingSenderId: "183444708388",
  appId: "1:183444708388:web:b112e521dc8d7a9529ccb1",
  measurementId: "G-XBSBG3FFRG",
};

// Initialize Firebase
initializeApp(firebaseConfig);
