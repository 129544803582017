import { PageNotFoundHandlerCollection } from ".";
import { App } from "../App";
import { ScreenStateKind } from "../state";

export function getPageNotFoundController(
  app: App
): PageNotFoundHandlerCollection {
  function onHomeButtonClicked(): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.PageNotFound) {
        return oldState;
      }

      const { user } = oldState;

      if (user === null) {
        return {
          ...oldState,
          screen: { kind: ScreenStateKind.Home },
        };
      }

      if (!user.isEmailVerified) {
        return {
          ...oldState,
          screen: { kind: ScreenStateKind.VerifyEmail, user },
        };
      }

      return {
        ...oldState,
        screen: { kind: ScreenStateKind.Dashboard, user },
      };
    });
  }

  return {
    onHomeButtonClicked,
  };
}
