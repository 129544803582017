import { VerifyEmailHandlerCollection } from ".";
import { App } from "../App";
import { sendEmailVerification, getAuth, signOut } from "firebase/auth";

export function getVerifyEmailController(
  app: App
): VerifyEmailHandlerCollection {
  function onSendEmailButtonClicked(): void {
    const auth = getAuth();
    if (auth.currentUser === null) {
      return;
    }

    sendEmailVerification(auth.currentUser).then(() => {
      alert("We sent the email!");
    });
  }

  function onSignoutButtonClicked() {
    signOut(getAuth());
  }

  return {
    onSendEmailButtonClicked,
    onSignoutButtonClicked,
  };
}
