import { PATHNAMES } from "./stateLogic/pathnames";

export type AppProps = {};

export interface AppState {
  readonly screen: ScreenState;
  readonly existingEmailAddresses: readonly string[];
  readonly malformedEmailAddresses: readonly string[];
  readonly user: null | UserSummary;
}

export type ScreenState =
  | AwaitingAuthScreenState
  | HomeScreenState
  | AboutScreenState
  | FaqScreenState
  | LoginScreenState
  | SignupScreenState
  | VerifyEmailScreenState
  | FindACourseScreenState
  | EnrollInPythonScreenState
  | DashboardScreenState
  | LearnPythonScreenState
  | PageNotFoundScreenState;

export enum ScreenStateKind {
  AwaitingAuth = "AwaitingAuth",
  Home = "Home",
  About = "About",
  Faq = "Faq",
  Login = "Login",
  Signup = "Signup",
  VerifyEmail = "VerifyEmail",
  FindACourse = "FindACourse",
  EnrollInPython = "EnrollInPython",
  Dashboard = "Dashboard",
  LearnPython = "LearnPython",
  PageNotFound = "PageNotFound",
}

export interface AwaitingAuthScreenState {
  readonly kind: ScreenStateKind.AwaitingAuth;
}

const DEFAULT_AWAITING_AUTH_SCREEN_STATE: AwaitingAuthScreenState = {
  kind: ScreenStateKind.AwaitingAuth,
};

export interface HomeScreenState {
  readonly kind: ScreenStateKind.Home;
}

const DEFAULT_HOME_SCREEN_STATE: HomeScreenState = {
  kind: ScreenStateKind.Home,
};

export interface AboutScreenState {
  readonly kind: ScreenStateKind.About;
}

const DEFAULT_ABOUT_SCREEN_STATE: AboutScreenState = {
  kind: ScreenStateKind.About,
};

export interface FaqScreenState {
  readonly kind: ScreenStateKind.Faq;
}

const DEFAULT_FAQ_SCREEN_STATE: FaqScreenState = {
  kind: ScreenStateKind.Faq,
};

export interface SignupScreenState {
  readonly kind: ScreenStateKind.Signup;
  readonly email: string;
  readonly password: string;
  readonly confirmPassword: string;
  readonly displayName: string;
  readonly didCurrentSubmissionAttemptFail: boolean;
  readonly isSubmitting: boolean;
}

const DEFAULT_SIGNUP_SCREEN_STATE: SignupScreenState = {
  kind: ScreenStateKind.Signup,
  email: "",
  password: "",
  confirmPassword: "",
  displayName: "",
  didCurrentSubmissionAttemptFail: false,
  isSubmitting: false,
};

export interface LoginScreenState {
  readonly kind: ScreenStateKind.Login;
  readonly email: string;
  readonly password: string;
  readonly nextPathname: string;
  readonly didCurrentSubmissionAttemptFail: boolean;
  readonly isSubmitting: boolean;
}

const DEFAULT_LOGIN_SCREEN_STATE: LoginScreenState = {
  kind: ScreenStateKind.Login,
  email: "",
  password: "",
  nextPathname: PATHNAMES.DASHBOARD,
  didCurrentSubmissionAttemptFail: false,
  isSubmitting: false,
};

export interface VerifyEmailScreenState {
  readonly kind: ScreenStateKind.VerifyEmail;
  readonly user: UnverifiedUserSummary;
}

const DEFAULT_VERIFY_EMAIL_SCREEN_STATE: VerifyEmailScreenState = {
  kind: ScreenStateKind.VerifyEmail,
  user: {
    uid: "",
    isEmailVerified: false,
  },
};

export interface FindACourseScreenState {
  readonly kind: ScreenStateKind.FindACourse;
}

const DEFAULT_FIND_A_COURSE_SCREEN_STATE: FindACourseScreenState = {
  kind: ScreenStateKind.FindACourse,
};

export interface EnrollInPythonScreenState {
  readonly kind: ScreenStateKind.EnrollInPython;
}

const DEFAULT_ENROLL_IN_PYTHON_SCREEN_STATE: EnrollInPythonScreenState = {
  kind: ScreenStateKind.EnrollInPython,
};

export interface DashboardScreenState {
  readonly kind: ScreenStateKind.Dashboard;
  readonly user: VerifiedUserSummary;
}

function getDefaultDashboardScreenState(
  user: VerifiedUserSummary
): DashboardScreenState {
  return {
    kind: ScreenStateKind.Dashboard,
    user,
  };
}

export interface LearnPythonScreenState {
  readonly kind: ScreenStateKind.LearnPython;
  readonly user: VerifiedUserSummary;
}

function getDefaultLearnPythonScreenState(
  user: VerifiedUserSummary
): LearnPythonScreenState {
  return {
    kind: ScreenStateKind.LearnPython,
    user,
  };
}

export interface PageNotFoundScreenState {
  readonly kind: ScreenStateKind.PageNotFound;
}

const DEFAULT_PAGE_NOT_FOUND_SCREEN_STATE: PageNotFoundScreenState = {
  kind: ScreenStateKind.PageNotFound,
};

export type UserSummary = VerifiedUserSummary | UnverifiedUserSummary;

export interface VerifiedUserSummary extends UserSummaryBase {
  readonly isEmailVerified: true;
}

export interface UnverifiedUserSummary extends UserSummaryBase {
  readonly isEmailVerified: false;
}

export interface UserSummaryBase {
  readonly uid: string;
}

export const DEFAULT_SCREEN_STATES = {
  AWAITING_AUTH: DEFAULT_AWAITING_AUTH_SCREEN_STATE,
  HOME: DEFAULT_HOME_SCREEN_STATE,
  ABOUT: DEFAULT_ABOUT_SCREEN_STATE,
  FAQ: DEFAULT_FAQ_SCREEN_STATE,
  SIGNUP: DEFAULT_SIGNUP_SCREEN_STATE,
  LOGIN: DEFAULT_LOGIN_SCREEN_STATE,
  VERIFY_EMAIL: DEFAULT_VERIFY_EMAIL_SCREEN_STATE,
  FIND_A_COURSE: DEFAULT_FIND_A_COURSE_SCREEN_STATE,
  ENROLL_IN_PYTHON: DEFAULT_ENROLL_IN_PYTHON_SCREEN_STATE,
  dashboard: getDefaultDashboardScreenState,
  learnPython: getDefaultLearnPythonScreenState,
  PAGE_NOT_FOUND: DEFAULT_PAGE_NOT_FOUND_SCREEN_STATE,
} as const;
