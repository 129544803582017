import type * as react from "react";
import { LoginHandlerCollection } from ".";
import { App } from "../App";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ScreenStateKind } from "../state";
import {
  getObviousLoginInfoProblems,
  hasAnyProblem,
} from "../stateLogic/loginInfoProblems";
import {
  INVALID_CREDENTIAL,
  INVALID_EMAIL,
  USER_DISABLED,
  USER_NOT_FOUND,
  WRONG_PASSWORD,
} from "../firebaseAuthCodes";

export function getLoginController(app: App): LoginHandlerCollection {
  function onEmailChanged(event: react.ChangeEvent<HTMLInputElement>): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Login) {
        return oldState;
      }

      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          email: event.target.value,
        },
      };
    });
  }

  function onPasswordChanged(event: react.ChangeEvent<HTMLInputElement>): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Login) {
        return oldState;
      }

      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          password: event.target.value,
        },
      };
    });
  }

  function onSubmitButtonClicked(event: react.MouseEvent): void {
    event.preventDefault();

    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Login) {
        return oldState;
      }

      const { email, password } = oldState.screen;

      const problems = getObviousLoginInfoProblems(oldState);

      if (hasAnyProblem(problems)) {
        return oldState;
      }

      // We use this flag to handle the race condition between:
      // 1. The `replaceState` callback, where we set `isSubmitting` to `true`.
      // 2. The call to `signInWithEmailAndPassword`.
      // Specifically, we don't want to set `isSubmitting` to `true` if
      // that callback is called after `signInWithEmailAndPassword` resolves.
      let didRequestFail = false;

      app.replaceState((oldState) => {
        if (oldState.screen.kind !== ScreenStateKind.Login || didRequestFail) {
          return oldState;
        }
        return {
          ...oldState,
          screen: {
            ...oldState.screen,
            isSubmitting: true,
          },
        };
      });

      signInWithEmailAndPassword(getAuth(), email, password).catch((error) => {
        didRequestFail = true;

        if (error.code === INVALID_EMAIL) {
          app.replaceState((oldState) => {
            if (oldState.screen.kind !== ScreenStateKind.Login) {
              return oldState;
            }
            return {
              ...oldState,
              screen: {
                ...oldState.screen,
                didCurrentSubmissionAttemptFail: true,
                isSubmitting: false,
              },
              malformedEmailAddresses: oldState.malformedEmailAddresses.concat([
                email,
              ]),
            };
          });
          return;
        }

        app.replaceState((oldState) => {
          if (oldState.screen.kind !== ScreenStateKind.Login) {
            return oldState;
          }
          return {
            ...oldState,
            screen: {
              ...oldState.screen,
              didCurrentSubmissionAttemptFail: true,
              isSubmitting: false,
            },
          };
        });

        if (
          ![
            INVALID_EMAIL,
            USER_DISABLED,
            USER_NOT_FOUND,
            WRONG_PASSWORD,
            INVALID_CREDENTIAL,
          ].includes(error.code)
        ) {
          throw error;
        }
      });

      return oldState;
    });
  }

  function onNavigateToSignupButtonClicked(event: react.MouseEvent): void {
    event.preventDefault();

    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Login) {
        return oldState;
      }

      return {
        ...oldState,
        screen: {
          kind: ScreenStateKind.Signup,
          email: "",
          password: "",
          confirmPassword: "",
          displayName: "",
          didCurrentSubmissionAttemptFail: false,
          isSubmitting: false,
        },
      };
    });
  }

  return {
    onEmailChanged,
    onPasswordChanged,
    onSubmitButtonClicked,
    onNavigateToSignupButtonClicked,
  };
}
