import { SignupHandlerCollection } from ".";
import { App } from "../App";
import type * as react from "react";
import { ScreenStateKind } from "../state";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import {
  getObviousSignupInfoProblems,
  hasAnyProblem,
  replaceUnicodeSeparatorsWithSpace,
} from "../stateLogic/signupInfoProblems";
import { EMAIL_IN_USE, INVALID_EMAIL } from "../firebaseAuthCodes";

export function getSignupController(app: App): SignupHandlerCollection {
  function onEmailChanged(event: react.ChangeEvent<HTMLInputElement>): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Signup) {
        return oldState;
      }
      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          email: event.target.value,
        },
      };
    });
  }

  function onPasswordChanged(event: react.ChangeEvent<HTMLInputElement>): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Signup) {
        return oldState;
      }
      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          password: event.target.value,
        },
      };
    });
  }

  function onConfirmPasswordChanged(
    event: react.ChangeEvent<HTMLInputElement>
  ): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Signup) {
        return oldState;
      }
      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          confirmPassword: event.target.value,
        },
      };
    });
  }

  function onDisplayNameChanged(
    event: react.ChangeEvent<HTMLInputElement>
  ): void {
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Signup) {
        return oldState;
      }
      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          displayName: event.target.value,
        },
      };
    });
  }

  function onSubmitButtonClicked(event: react.MouseEvent): void {
    event.preventDefault();

    const { screen } = app.state;
    if (screen.kind !== ScreenStateKind.Signup) {
      return;
    }

    const problems = getObviousSignupInfoProblems(app.state);
    if (hasAnyProblem(problems)) {
      app.replaceState((oldState) => {
        if (oldState.screen.kind !== ScreenStateKind.Signup) {
          return oldState;
        }
        return {
          ...oldState,
          screen: {
            ...oldState.screen,
            didCurrentSubmissionAttemptFail: true,
          },
        };
      });
      return;
    }

    // We use this flag to handle the race condition between:
    // 1. The `replaceState` callback, where we set `isSubmitting` to `true`.
    // 2. The call to `createUserWithEmailAndPassword`.
    // Specifically, we don't want to set `isSubmitting` to `true` if
    // that callback is called after `createUserWithEmailAndPassword` resolves.
    let didRequestFail = false;

    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Signup || didRequestFail) {
        return oldState;
      }
      return {
        ...oldState,
        screen: {
          ...oldState.screen,
          isSubmitting: true,
        },
      };
    });

    createUserWithEmailAndPassword(getAuth(), screen.email, screen.password)
      .then((result) => {
        const displayName = replaceUnicodeSeparatorsWithSpace(
          screen.displayName
        );
        return updateProfile(result.user, { displayName });
      })
      .catch((error) => {
        didRequestFail = true;

        if (error.code === EMAIL_IN_USE) {
          app.replaceState((oldState) => {
            if (oldState.screen.kind !== ScreenStateKind.Signup) {
              return oldState;
            }
            return {
              ...oldState,
              screen: {
                ...oldState.screen,
                didCurrentSubmissionAttemptFail: true,
                isSubmitting: false,
              },
              existingEmailAddresses: oldState.existingEmailAddresses.concat([
                screen.email,
              ]),
            };
          });
          return;
        }

        if (error.code === INVALID_EMAIL) {
          app.replaceState((oldState) => {
            if (oldState.screen.kind !== ScreenStateKind.Signup) {
              return oldState;
            }
            return {
              ...oldState,
              screen: {
                ...oldState.screen,
                didCurrentSubmissionAttemptFail: true,
                isSubmitting: false,
              },
              malformedEmailAddresses: oldState.malformedEmailAddresses.concat([
                screen.email,
              ]),
            };
          });
          return;
        }

        app.replaceState((oldState) => {
          if (oldState.screen.kind !== ScreenStateKind.Signup) {
            return oldState;
          }
          return {
            ...oldState,
            screen: {
              ...oldState.screen,
              didCurrentSubmissionAttemptFail: true,
              isSubmitting: false,
            },
          };
        });
        throw error;
      });
  }

  function onNavigateToLoginButtonClicked(event: react.MouseEvent): void {
    event.preventDefault();
    app.replaceState((oldState) => {
      if (oldState.screen.kind !== ScreenStateKind.Signup) {
        return oldState;
      }
      return {
        ...oldState,
        screen: {
          kind: ScreenStateKind.Login,
          email: "",
          password: "",
          nextPathname: "",
          didCurrentSubmissionAttemptFail: false,
          isSubmitting: false,
        },
      };
    });
  }

  return {
    onEmailChanged,
    onPasswordChanged,
    onConfirmPasswordChanged,
    onDisplayNameChanged,
    onSubmitButtonClicked,
    onNavigateToLoginButtonClicked,
  };
}
