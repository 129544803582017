import { AppState, ScreenStateKind, VerifiedUserSummary } from "../state";

/**
 * These pathnames must not start or end with a slash.
 */
export const PATHNAMES = {
  HOME: "",
  ABOUT: "about",
  FAQ: "faq",
  LOGIN: "login",
  SIGNUP: "signup",
  COMPLETE_SIGNUP: "complete-signup",
  FIND_A_COURSE: "find-a-course",
  ENROLL_IN_PYTHON: "enroll/py001_001",
  DASHBOARD: "dashboard",
  LEARN_PYTHON: "learn/py001_001",
} as const;

export function getPathnameFromState(state: AppState): string {
  switch (state.screen.kind) {
    case ScreenStateKind.AwaitingAuth:
      return window.location.pathname;
    case ScreenStateKind.Home:
      return "/" + PATHNAMES.HOME;
    case ScreenStateKind.About:
      return "/" + PATHNAMES.ABOUT;
    case ScreenStateKind.Faq:
      return "/" + PATHNAMES.FAQ;
    case ScreenStateKind.Login:
      return "/" + PATHNAMES.LOGIN;
    case ScreenStateKind.Signup:
      return "/" + PATHNAMES.SIGNUP;
    case ScreenStateKind.VerifyEmail:
      return "/" + PATHNAMES.COMPLETE_SIGNUP;
    case ScreenStateKind.FindACourse:
      return "/" + PATHNAMES.FIND_A_COURSE;
    case ScreenStateKind.EnrollInPython:
      return "/" + PATHNAMES.ENROLL_IN_PYTHON;
    case ScreenStateKind.Dashboard:
      return "/" + PATHNAMES.DASHBOARD;
    case ScreenStateKind.LearnPython:
      return "/" + PATHNAMES.LEARN_PYTHON;
    case ScreenStateKind.PageNotFound:
      return window.location.pathname;
  }
}

export function getNextPathnameNowThatUserLoggedIn(
  state: AppState,
  user: VerifiedUserSummary
): string {
  const naivePathname = removeStartingAndEndingSlash(
    getNaiveNextPathnameNowThatUserLoggedIn(state, user)
  );

  if (
    naivePathname === PATHNAMES.LOGIN ||
    naivePathname === PATHNAMES.SIGNUP ||
    (naivePathname === PATHNAMES.COMPLETE_SIGNUP && user.isEmailVerified)
  ) {
    return leftNormalizePathname(PATHNAMES.DASHBOARD);
  }

  return leftNormalizePathname(naivePathname);
}

export function getNaiveNextPathnameNowThatUserLoggedIn(
  state: AppState,
  _userProof: VerifiedUserSummary
): string {
  const { screen } = state;

  if (screen.kind === ScreenStateKind.Login && screen.nextPathname.length > 0) {
    return screen.nextPathname;
  }

  return window.location.pathname;
}

export function removeStartingAndEndingSlash(pathname: string): string {
  const start = pathname.startsWith("/") ? 1 : 0;
  const end = pathname.endsWith("/") ? -1 : undefined;
  return pathname.slice(start, end);
}

/**
 * A left-normalized pathname is a pathname that starts with a slash,
 * but does not end with a slash, UNLESS it is the root pathname.
 * If the pathname is the root, the left normal form is `"/"`.
 */
export function leftNormalizePathname(s: string): string {
  if (s === "" || s === "/") {
    return "/";
  }

  const end = s.endsWith("/") ? -1 : undefined;
  if (s.startsWith("/")) {
    return s.slice(0, end);
  }

  return "/" + s.slice(0, end);
}
