import { getAuth, signOut } from "firebase/auth";
import { DashboardHandlerCollection } from ".";
import { App } from "../App";

export function getDashboardController(app: App): DashboardHandlerCollection {
  function onSignoutButtonClicked() {
    signOut(getAuth());
  }

  return {
    onSignoutButtonClicked,
  };
}
