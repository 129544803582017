import type * as react from "react";
import { getLoginController } from "./login";
import { App } from "../App";
import { getPageNotFoundController } from "./pageNotFound";
import { getVerifyEmailController } from "./verifyEmail";
import { getDashboardController } from "./dashboard";
import { getSignupController } from "./signup";

export interface HandlerSupercollection {
  readonly login: LoginHandlerCollection;
  readonly verifyEmail: VerifyEmailHandlerCollection;
  readonly pageNotFound: PageNotFoundHandlerCollection;
  readonly dashboard: DashboardHandlerCollection;
  readonly signup: SignupHandlerCollection;
}

export interface LoginHandlerCollection {
  onEmailChanged(event: react.ChangeEvent<HTMLInputElement>): void;
  onPasswordChanged(event: react.ChangeEvent<HTMLInputElement>): void;
  onSubmitButtonClicked(event: react.MouseEvent): void;
  onNavigateToSignupButtonClicked(event: react.MouseEvent): void;
}

export interface VerifyEmailHandlerCollection {
  onSendEmailButtonClicked(): void;
  onSignoutButtonClicked(): void;
}

export interface PageNotFoundHandlerCollection {
  onHomeButtonClicked(): void;
}

export interface DashboardHandlerCollection {
  onSignoutButtonClicked(): void;
}

export interface SignupHandlerCollection {
  onEmailChanged(event: react.ChangeEvent<HTMLInputElement>): void;
  onPasswordChanged(event: react.ChangeEvent<HTMLInputElement>): void;
  onConfirmPasswordChanged(event: react.ChangeEvent<HTMLInputElement>): void;
  onDisplayNameChanged(event: react.ChangeEvent<HTMLInputElement>): void;
  onSubmitButtonClicked(event: react.MouseEvent): void;
  onNavigateToLoginButtonClicked(event: react.MouseEvent): void;
}

export function getHandlers(app: App): HandlerSupercollection {
  return {
    login: getLoginController(app),
    verifyEmail: getVerifyEmailController(app),
    pageNotFound: getPageNotFoundController(app),
    dashboard: getDashboardController(app),
    signup: getSignupController(app),
  };
}
