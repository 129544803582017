import type * as react from "react";
import { LoginHandlerCollection } from "../handlers";
import { AppState, LoginScreenState } from "../state";
import { MIN_PASSWORD_LENGTH } from "../stateLogic/password";
import {
  getObviousLoginInfoProblems,
  hasAnyProblem,
} from "../stateLogic/loginInfoProblems";

export interface LoginMenuProps {
  readonly state: AppState;
  readonly screen: LoginScreenState;
  readonly handler: LoginHandlerCollection;
}

export function LoginMenu({
  state,
  screen,
  handler,
}: LoginMenuProps): react.ReactElement {
  const problems = getObviousLoginInfoProblems(state);
  const { isSubmitting } = screen;

  return (
    <div>
      <h2>Log in</h2>
      <form>
        <label>
          Email:
          <input
            type="text"
            className={
              problems.obviouslyMalformedEmailAddress ? "Input--invalid" : ""
            }
            readOnly={isSubmitting}
            value={screen.email}
            onChange={handler.onEmailChanged}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            className={problems.invalidPassword ? "Input--invalid" : ""}
            readOnly={isSubmitting}
            value={screen.password}
            onChange={handler.onPasswordChanged}
          />
        </label>
        <button
          disabled={isSubmitting || hasAnyProblem(problems)}
          onClick={handler.onSubmitButtonClicked}
        >
          Log in
        </button>
      </form>

      {hasAnyProblem(problems) && (
        <div>
          Problems:
          <ul>
            {problems.obviouslyMalformedEmailAddress && (
              <li>Invalid email address</li>
            )}

            {problems.invalidPassword && (
              <li>
                Password too short. Password must be at least{" "}
                {MIN_PASSWORD_LENGTH} characters.
              </li>
            )}
          </ul>
        </div>
      )}

      {screen.didCurrentSubmissionAttemptFail && (
        <div>Login failed. Invalid email or password.</div>
      )}

      <div>
        <p>Don't have an account yet?</p>
        <button
          disabled={isSubmitting}
          onClick={handler.onNavigateToSignupButtonClicked}
        >
          Sign up
        </button>
      </div>
    </div>
  );
}
