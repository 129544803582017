import type * as react from "react";
import { SignupHandlerCollection } from "../handlers";
import { AppState, SignupScreenState } from "../state";
import {
  getObviousSignupInfoProblems,
  hasAnyProblem,
  hasEmailAddressProblem,
  hasPasswordProblem,
} from "../stateLogic/signupInfoProblems";
import { MIN_PASSWORD_LENGTH } from "../stateLogic/password";

export interface SignupMenuProps {
  readonly state: AppState;
  readonly screen: SignupScreenState;
  readonly handler: SignupHandlerCollection;
}

export function SignupMenu({
  state,
  screen,
  handler,
}: SignupMenuProps): react.ReactElement {
  const { didCurrentSubmissionAttemptFail, isSubmitting } = screen;
  const problems = getObviousSignupInfoProblems(state);

  const flagEmail =
    didCurrentSubmissionAttemptFail && hasEmailAddressProblem(problems);
  const flagPassword =
    didCurrentSubmissionAttemptFail && hasPasswordProblem(problems);
  const flagConfirmPassword = problems.passwordsDontMatch;
  const flagDisplayName =
    didCurrentSubmissionAttemptFail && problems.invalidDisplayName;

  return (
    <div>
      <h2>Sign up</h2>
      <form>
        <label>
          Email:
          <input
            type="text"
            className={flagEmail ? "Input--invalid" : ""}
            readOnly={isSubmitting}
            value={screen.email}
            onChange={handler.onEmailChanged}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            className={flagPassword ? "Input--invalid" : ""}
            readOnly={isSubmitting}
            value={screen.password}
            onChange={handler.onPasswordChanged}
          />
        </label>
        <label>
          Confirm password:
          <input
            type="password"
            className={flagConfirmPassword ? "Input--invalid" : ""}
            readOnly={isSubmitting}
            value={screen.confirmPassword}
            onChange={handler.onConfirmPasswordChanged}
          />
        </label>
        <label>
          Display name:
          <input
            type="text"
            className={flagDisplayName ? "Input--invalid" : ""}
            readOnly={isSubmitting}
            value={screen.displayName}
            onChange={handler.onDisplayNameChanged}
          />
        </label>
        <button
          disabled={isSubmitting || hasAnyProblem(problems)}
          onClick={handler.onSubmitButtonClicked}
        >
          Create account
        </button>
      </form>
      {hasAnyProblem(problems) && (
        <div>
          Problems:
          <ul>
            {problems.obviouslyMalformedEmailAddress && (
              <li>Invalid email address</li>
            )}

            {problems.emailAddressInUse && <li>Email address in use</li>}

            {problems.invalidPassword && (
              <li>
                Invalid password (must be at leaast {MIN_PASSWORD_LENGTH}{" "}
                characters)
              </li>
            )}

            {problems.passwordsDontMatch && <li>Passwords don't match</li>}

            {problems.invalidDisplayName && (
              <li>
                Invalid display name. Special characters and multiple
                consecutive spaces are not allowed.
              </li>
            )}
          </ul>
        </div>
      )}

      {isSubmitting ? (
        <div>Creating account...</div>
      ) : (
        <div>
          <p>Already have an account?</p>
          <button onClick={handler.onNavigateToLoginButtonClicked}>
            Login
          </button>
        </div>
      )}
    </div>
  );
}
