import {
  SPACE_SEPARATED_UNICODE_LETTER_SEQUENCES,
  UNICODE_WHITESPACE_G,
} from "../regex";
import { AppState, ScreenStateKind } from "../state";
import { isEmailObviouslyMalformed } from "./emailAddress";
import { MIN_PASSWORD_LENGTH } from "./password";

export interface SignupInfoProblems {
  obviouslyMalformedEmailAddress: boolean;
  emailAddressInUse: boolean;
  invalidPassword: boolean;
  passwordsDontMatch: boolean;
  invalidDisplayName: boolean;
}

export function replaceUnicodeSeparatorsWithSpace(s: string): string {
  UNICODE_WHITESPACE_G.lastIndex = 0;
  return s.replace(UNICODE_WHITESPACE_G, " ");
}

export function getObviousSignupInfoProblems(
  state: AppState
): SignupInfoProblems {
  const problems: SignupInfoProblems = {
    obviouslyMalformedEmailAddress: false,
    emailAddressInUse: false,
    invalidPassword: false,
    passwordsDontMatch: false,
    invalidDisplayName: false,
  };

  const { screen } = state;
  if (screen.kind !== ScreenStateKind.Signup) {
    return problems;
  }

  const { email } = screen;
  if (
    isEmailObviouslyMalformed(email) ||
    state.malformedEmailAddresses.includes(email)
  ) {
    problems.obviouslyMalformedEmailAddress = true;
  }

  if (state.existingEmailAddresses.includes(email)) {
    problems.emailAddressInUse = true;
  }

  if (screen.password.length < MIN_PASSWORD_LENGTH) {
    problems.invalidPassword = true;
  }

  if (screen.password !== screen.confirmPassword) {
    problems.passwordsDontMatch = true;
  }

  if (
    !SPACE_SEPARATED_UNICODE_LETTER_SEQUENCES.test(
      replaceUnicodeSeparatorsWithSpace(screen.displayName)
    )
  ) {
    problems.invalidDisplayName = true;
  }

  return problems;
}

export function hasEmailAddressProblem(problems: SignupInfoProblems): boolean {
  return problems.obviouslyMalformedEmailAddress || problems.emailAddressInUse;
}

export function hasPasswordProblem(problems: SignupInfoProblems): boolean {
  return problems.invalidPassword || problems.passwordsDontMatch;
}

export function hasAnyProblem(problems: SignupInfoProblems): boolean {
  return (
    problems.obviouslyMalformedEmailAddress ||
    problems.emailAddressInUse ||
    problems.invalidPassword ||
    problems.passwordsDontMatch ||
    problems.invalidDisplayName
  );
}
