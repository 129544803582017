import { firebaseAuth } from "../externalTypeNamespaces";
import {
  DEFAULT_SCREEN_STATES,
  ScreenState,
  UserSummary,
  VerifiedUserSummary,
} from "../state";
import { PATHNAMES, removeStartingAndEndingSlash } from "./pathnames";

export function noOp(): void {}

export function getUserSummary(user: firebaseAuth.User): UserSummary {
  return { uid: user.uid, isEmailVerified: user.emailVerified };
}

export function isPathnamePublic(pathname: string): boolean {
  const strippedPathname = removeStartingAndEndingSlash(pathname);
  const PUBLIC_PATHS: readonly string[] = [
    PATHNAMES.HOME,
    PATHNAMES.ABOUT,
    PATHNAMES.FAQ,
    PATHNAMES.LOGIN,
    PATHNAMES.SIGNUP,
    PATHNAMES.FIND_A_COURSE,
    PATHNAMES.ENROLL_IN_PYTHON,
  ];
  return PUBLIC_PATHS.includes(strippedPathname);
}

export function getDefaultScreenStateFromPublicPathname(
  pathname: string
): ScreenState {
  const strippedPathname = removeStartingAndEndingSlash(pathname);

  if (strippedPathname === PATHNAMES.HOME) {
    return DEFAULT_SCREEN_STATES.HOME;
  }

  if (strippedPathname === PATHNAMES.ABOUT) {
    return DEFAULT_SCREEN_STATES.ABOUT;
  }

  if (strippedPathname === PATHNAMES.FAQ) {
    return DEFAULT_SCREEN_STATES.ABOUT;
  }

  if (strippedPathname === PATHNAMES.LOGIN) {
    return DEFAULT_SCREEN_STATES.LOGIN;
  }

  if (strippedPathname === PATHNAMES.SIGNUP) {
    return DEFAULT_SCREEN_STATES.SIGNUP;
  }

  if (strippedPathname === PATHNAMES.FIND_A_COURSE) {
    return DEFAULT_SCREEN_STATES.FIND_A_COURSE;
  }

  if (strippedPathname === PATHNAMES.ENROLL_IN_PYTHON) {
    return DEFAULT_SCREEN_STATES.ENROLL_IN_PYTHON;
  }

  throw new Error(
    "Invalid public pathname: " + JSON.stringify(strippedPathname)
  );
}

export function isPathnamePrivate(pathname: string): boolean {
  const strippedPathname = removeStartingAndEndingSlash(pathname);
  const PRIVATE_PATHS: readonly string[] = [
    PATHNAMES.COMPLETE_SIGNUP,
    PATHNAMES.DASHBOARD,
    PATHNAMES.LEARN_PYTHON,
  ];
  return PRIVATE_PATHS.includes(strippedPathname);
}

export function getDefaultScreenFromPrivatePathname(
  pathname: string,
  user: VerifiedUserSummary
): ScreenState {
  const strippedPathname = removeStartingAndEndingSlash(pathname);

  if (strippedPathname === PATHNAMES.COMPLETE_SIGNUP) {
    return DEFAULT_SCREEN_STATES.VERIFY_EMAIL;
  }

  if (strippedPathname === PATHNAMES.DASHBOARD) {
    return DEFAULT_SCREEN_STATES.dashboard(user);
  }

  if (strippedPathname === PATHNAMES.LEARN_PYTHON) {
    return DEFAULT_SCREEN_STATES.learnPython(user);
  }

  throw new Error(
    "Invalid private pathname: " + JSON.stringify(strippedPathname)
  );
}
