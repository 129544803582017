import React from "react";
import "./App.css";
import { getAuth } from "firebase/auth";
import { HandlerSupercollection, getHandlers } from "./handlers";
import {
  getNextPathnameNowThatUserLoggedIn,
  getPathnameFromState,
  removeStartingAndEndingSlash,
} from "./stateLogic/pathnames";
import { SignupMenu } from "./components/SignupMenu";
import { firebaseAuth } from "./externalTypeNamespaces";
import {
  AppProps,
  AppState,
  DEFAULT_SCREEN_STATES,
  ScreenStateKind,
  UserSummary,
} from "./state";
import { addDebugHooks } from "./addDebugHooks";
import {
  noOp,
  getUserSummary,
  getDefaultScreenStateFromPublicPathname,
  isPathnamePublic,
  getDefaultScreenFromPrivatePathname as getDefaultScreenStateFromPrivatePathname,
  isPathnamePrivate,
} from "./stateLogic";
import { LoginMenu } from "./components/LoginMenu";

export class App extends React.Component<AppProps, AppState> {
  removeOnAuthStateChangedListener: () => void;

  handlers: HandlerSupercollection;

  constructor(props: App) {
    super(props);

    this.bindMethods();

    this.state = {
      screen: { kind: ScreenStateKind.AwaitingAuth },
      existingEmailAddresses: [],
      malformedEmailAddresses: [],
      user: null,
    };

    this.removeOnAuthStateChangedListener = noOp;

    this.handlers = getHandlers(this);

    addDebugHooks(this);
  }

  render() {
    // TODO: This is just a hack.
    if (
      /^\/castle-coders\/user-agreement-version-1\/?$/.test(
        window.location.pathname
      )
    ) {
      return <UserAgreementVersion1 />;
    }
    if (
      /^\/castle-coders\/contact-support\/?$/.test(window.location.pathname)
    ) {
      const CASTLE_CODERS_SUPPORT_PAGE_URL =
        "https://docs.google.com/forms/d/e/1FAIpQLSe0PC_yNOxWveFQ-A4lA4IopmAAok56TgX0pVJZYMwy6hhQwA/viewform";
      window.location.href = CASTLE_CODERS_SUPPORT_PAGE_URL;
      return (
        <div>
          We are redirecting you to another page. If you are not automatically
          redirected, please click{" "}
          <a href={CASTLE_CODERS_SUPPORT_PAGE_URL}>here</a>.
        </div>
      );
    }

    const { screen } = this.state;
    switch (screen.kind) {
      case ScreenStateKind.AwaitingAuth:
        return <div></div>;
      case ScreenStateKind.Home:
        return <div>TODO: Home</div>;
      case ScreenStateKind.About:
        return <div>TODO: About</div>;
      case ScreenStateKind.Faq:
        return <div>TODO: FAQ</div>;
      case ScreenStateKind.Login:
        return (
          <LoginMenu
            state={this.state}
            screen={screen}
            handler={this.handlers.login}
          />
        );
      case ScreenStateKind.Signup:
        return (
          <SignupMenu
            state={this.state}
            screen={screen}
            handler={this.handlers.signup}
          />
        );
      case ScreenStateKind.VerifyEmail:
        return (
          <div>
            <h2>Email verification required</h2>
            <p>
              <span style={{ fontWeight: "bold", color: "red" }}>
                First, click the below button.
              </span>{" "}
              Then check your email.
            </p>
            <button
              onClick={this.handlers.verifyEmail.onSendEmailButtonClicked}
            >
              Send email
            </button>
            <button onClick={this.handlers.verifyEmail.onSignoutButtonClicked}>
              Sign out
            </button>
          </div>
        );
      case ScreenStateKind.FindACourse:
        return <div>TODO: Find a course</div>;
      case ScreenStateKind.EnrollInPython:
        return <div>TODO: Enroll in Python</div>;
      case ScreenStateKind.Dashboard:
        return (
          <div>
            <h2>Dashboard</h2>
            <button onClick={this.handlers.dashboard.onSignoutButtonClicked}>
              Sign out
            </button>
          </div>
        );
      case ScreenStateKind.LearnPython:
        return <div>TODO: Learn Python</div>;
      case ScreenStateKind.PageNotFound:
        return (
          <div>
            <h2>404: Page not found.</h2>
            <button onClick={this.handlers.pageNotFound.onHomeButtonClicked}>
              Return home
            </button>
          </div>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _exhaustiveCheck: never = screen;
  }

  componentDidMount(): void {
    this.removeOnAuthStateChangedListener = getAuth().onAuthStateChanged(
      this.onAuthStateChanged
    );

    window.addEventListener("popstate", this.onPopState);
  }

  componentWillUnmount(): void {
    this.removeOnAuthStateChangedListener();
    window.removeEventListener("popstate", this.onPopState);
  }

  onPopState(event: PopStateEvent): void {
    // TODO
    console.log("onPopState", event.state);
  }

  onAuthStateChanged(firebaseUser: firebaseAuth.User | null): void {
    if (firebaseUser === null) {
      this.handleAuthStateChangeAssumingUserNotLoggedIn();
    } else {
      const user = getUserSummary(firebaseUser);
      this.handleAuthStateChangeAssumingUserLoggedIn(user);
    }
  }

  handleAuthStateChangeAssumingUserNotLoggedIn(): void {
    const strippedPathname = removeStartingAndEndingSlash(
      window.location.pathname
    );

    if (isPathnamePublic(strippedPathname)) {
      this.replaceState({
        screen: getDefaultScreenStateFromPublicPathname(strippedPathname),
      });
      return;
    }

    if (isPathnamePrivate(strippedPathname)) {
      this.replaceState({
        screen: {
          ...DEFAULT_SCREEN_STATES.LOGIN,
          nextPathname: strippedPathname,
        },
      });
      return;
    }

    this.replaceState((oldState) => ({
      ...oldState,
      screen: DEFAULT_SCREEN_STATES.PAGE_NOT_FOUND,
    }));
  }

  handleAuthStateChangeAssumingUserLoggedIn(user: UserSummary): void {
    if (!user.isEmailVerified) {
      this.replaceState({
        screen: {
          kind: ScreenStateKind.VerifyEmail,
          user,
        },
        user,
      });
      return;
    }

    const strippedPathname = removeStartingAndEndingSlash(
      getNextPathnameNowThatUserLoggedIn(this.state, user)
    );

    if (isPathnamePublic(strippedPathname)) {
      this.replaceState({
        screen: getDefaultScreenStateFromPublicPathname(strippedPathname),
        user,
      });
      return;
    }

    if (isPathnamePrivate(strippedPathname)) {
      this.replaceState({
        screen: getDefaultScreenStateFromPrivatePathname(
          strippedPathname,
          user
        ),
        user,
      });
      return;
    }

    this.replaceState({
      screen: DEFAULT_SCREEN_STATES.PAGE_NOT_FOUND,
      user,
    });
  }

  replaceState(
    newStateOrStateUpdater:
      | Partial<AppState>
      | ((oldState: AppState) => AppState)
  ): void {
    this.setState((oldState) => {
      if (typeof newStateOrStateUpdater === "function") {
        return newStateOrStateUpdater(oldState);
      }
      return { ...oldState, ...newStateOrStateUpdater };
    }, this.replaceHistoryStateBasedOnAppState);
  }

  replaceHistoryStateBasedOnAppState(): void {
    const newPathname = getPathnameFromState(this.state);
    window.history.replaceState(null, "", newPathname);
  }

  bindMethods(): void {
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.onPopState = this.onPopState.bind(this);
    this.replaceHistoryStateBasedOnAppState =
      this.replaceHistoryStateBasedOnAppState.bind(this);
  }
}

function UserAgreementVersion1(): React.ReactElement {
  return (
    <>
      <h1>Castle Coders User Agreement</h1>
      <p>Version 1, September 2024.</p>
      <p>
        This agreement is between you, the user, and Kyle Jeffrey Lin
        (&quot;Kyle&quot;). Throughout this agreement, references to
        &quot;we,&quot; &quot;us,&quot; or &quot;our&quot; mean Kyle Jeffrey Lin
        acting individually.
      </p>
      <p>
        By installing, using, or otherwise accessing Castle Coders, you affirm
        that you are the legal age of majority in your country of residence. If
        you are under the legal age of majority, your legal guardian must review
        and agree to this User Agreement on your behalf.
      </p>
      <p>
        A copy of this User Agreement may be found at{" "}
        <a href="https://zaqlo.com/castle-coders/user-agreement-version-1">
          https://zaqlo.com/castle-coders/user-agreement-version-1
        </a>
        .
      </p>
      <h2>Article I: Terms of Service</h2>
      <h3>1. Acceptance of Terms</h3>
      <p>
        By installing, using, or otherwise accessing Castle Coders, you agree to
        these Terms of Service. If you do not agree to these Terms of Service,
        please do not install, use, or otherwise access Castle Coders. If you do
        not agree to these Terms but have already installed Castle Coders,
        please stop using Castle Coders and delete Castle Coders immediately.
      </p>
      <p>Use of this service is void where prohibited.</p>
      <h3>2. Usage Rights and Restrictions</h3>
      <p>
        Castle Coders is licensed to you, not sold. This means you are granted a
        limited, non-exclusive, non-transferable, and revocable license to use
        the app for personal, non-commercial purposes.
      </p>
      <p>You may not:</p>
      <ul>
        <li>
          Transfer, copy, sell, rent, lease, or distribute the app to another
          person or to another device.
        </li>
        <li>
          Download or install the app through any unauthorized methods. You are
          only authorized to download and install the app through the Apple App
          Store, Google Play Store, or zaqlo.com. The app may not be available
          on any or all of these platforms at this time.
        </li>
        <li>Reverse engineer, decompile, or modify the app.</li>
        <li>
          Attempt to gain unauthorized access to any part of the app or its
          data.
        </li>
        <li>
          Perform any transmission to our cloud endpoints, except if the
          transmission is authorized in writing, or if the transmission is
          performed by an instance of the Castle Coders app that is being used
          in compliance with these Terms of Service.
        </li>
        <li>Share or publish the addresses of our cloud endpoints.</li>
        <li>
          Conduct any activity intended to disrupt the normal operation of our
          services, including but not limited to Denial of Service attacks.
        </li>
        <li>
          Use the app in any manner that violates these terms or any applicable
          law.
        </li>
      </ul>
      <p>
        We reserve the right to revoke your license to use Castle Coders at any
        time, without prior notice and without providing a reason. Upon
        revocation, you agree to stop using the app immediately and delete any
        copies of the software in your possession.
      </p>
      <h3>3. User Conduct</h3>
      <p>
        You agree to use Castle Coders in compliance with all applicable laws.
        Additionally, you agree to not:
      </p>
      <ul>
        <li>Hack, cheat, or tamper with the app.</li>
        <li>Disrupt or interfere with the experience of other users.</li>
        <li>Use the app to distribute harmful content, such as malware.</li>
        <li>
          Create any obscene, vulgar, sexual, hateful, discriminatory, or
          otherwise offensive content using the app.
        </li>
        <li>
          Create any obscene, vulgar, sexual, hateful, discriminatory, or
          otherwise offensive content using any asset of the app that does not
          come from a third-party.
        </li>
        <li>
          Perform any transmission to our cloud endpoints, except if the
          transmission is authorized in writing, or if the transmission is
          performed by an instance of the Castle Coders app that is being used
          in compliance with these Terms of Service.
        </li>
        <li>
          Conduct any activity intended to disrupt the normal operation of our
          services, including but not limited to Denial of Service attacks.
        </li>
      </ul>
      <h3>4. Privacy and Data Collection</h3>
      <p>
        Our Privacy Policy explains what data we collect and how it is used. By
        using Castle Coders, you acknowledge that you have read and understood
        the Privacy Policy.
      </p>
      <h3>5. Modifications to the App</h3>
      <p>
        We may update, modify, or remove features of Castle Coders at any time
        without prior notice. While we strive to keep the app available, we are
        not responsible for any downtime or temporary unavailability of
        features.
      </p>
      <h3>6. Termination</h3>
      <p>
        We may suspend or terminate your access to Castle Coders at any time,
        without prior notice or a reason. If we request that you stop using the
        app, you agree to immediately cease using the app and delete all copies
        you have access to, including any offline copies or backups.
      </p>
      <h3>7. Intellectual Property</h3>
      <p>
        All rights, title, and interest in and to the Castle Coders app,
        including but not limited to its code, graphics, sounds, and other
        content, are owned by Kyle, except for third-party assets used under
        license.
      </p>
      <p>
        <strong>In-Game Content:</strong>Any content created using the app, such
        as code that you create by dragging and dropping code blocks, for
        example, will be considered the intellectual property of Kyle. By
        creating such content, you agree that we have the right to use, modify,
        and distribute it, for any purpose we choose, without any compensation
        to you.
      </p>
      <p>
        You may not use, copy, modify, or distribute any content from the app
        (including both content created in-game as well as the software and
        assets that are included in the app) outside of the app without our
        express written consent.
      </p>
      <h3>8. Liability</h3>
      <p>
        Castle Coders is provided &quot;as is&quot; without warranties of any
        kind. We are not liable for any damages arising from your use of the
        app, including but not limited to data loss, game crashes, or technical
        issues. Your use of Castle Coders is at your own risk.
      </p>
      <h3>9. Governing Law and Arbitration</h3>
      <p>
        These Terms of Service and the Privacy Policy will be governed by the
        laws of the State of California, without regard to its conflict of laws
        principles. Any disputes arising from or related to the Terms of Service
        or Privacy Policy except for disputes described in the &quot;Exceptions
        to Agreement to Arbitrate&quot; section will be resolved on an
        individual basis through final and binding arbitration. The arbitration
        will be conducted in San Jose, California.
      </p>
      <p>
        Regardless of your location or nationality, U.S. law will govern any
        disputes, and you agree to resolve disputes in accordance with
        California law.
      </p>
      <h3>10. Exceptions to Agreement to Arbitrate</h3>
      <p>
        You agree that the arbitration agreement in the &quot;Governing Law and
        Arbitration&quot; section will not apply to the following disputes:
      </p>
      <ul>
        <li>
          <p>
            Claims about Kyle&#39;s intellectual property, such as claims to
            enforce, protect, or concerning the validity of Kyle&#39;s
            copyrights, trademarks, trade dress, domain names, patents, trade
            secrets, or other intellectual property rights.
          </p>
        </li>
        <li>
          <p>Claims related to piracy or tortious interference.</p>
        </li>
      </ul>
      <p>
        You agree that disputes not subject to arbitration shall be resolved
        exclusively through courts in San Jose, California. You consent to venue
        and personal jurisdiction in San Jose, California for all such claims or
        disputes.
      </p>
      <h3>11. Changes to the Terms</h3>
      <p>
        We may update these Terms of Service at any time. If we make significant
        changes, we will notify users by posting the updated terms within the
        app or on our website. Continued use of the app after changes are made
        means you accept the updated terms.
      </p>
      <h3>12. Severability</h3>
      <p>
        If any part of these Terms of Service is found to be invalid or
        unenforceable, the remaining provisions will remain in full force and
        effect.
      </p>
      <h3>13. No Waiver</h3>
      <p>
        Our failure to enforce any right or provision in these Terms of Service
        does not constitute a waiver of that right or provision.
      </p>
      <h2>Article II: Privacy Policy</h2>
      <p>
        We value your privacy and strive to be transparent about how we collect,
        use, and protect your data. This Privacy Policy explains what data we
        collect within Castle Coders, how we use it, and how we ensure your
        personal information stays private. Please note that data collected from
        Kyle&#39;s other apps and services may be subject to different privacy
        policies.
      </p>
      <h3>Data We Collect</h3>
      <p>
        We collect the following types of information while you use Castle
        Coders:
      </p>
      <p>
        <strong>Usage Information:</strong>This includes data such as which
        tutorials you complete, which buttons you press, and other interactions
        with the game.
      </p>
      <p>
        <strong>Gameplay Information:</strong>We track information like which
        code blocks you drag and how many tries it takes to complete each level.
        This helps us adjust difficulty and improve the quality of hints in
        future versions.
      </p>
      <p>
        <strong>Error Reports and Crash Data:</strong>If an error occurs or the
        game crashes, we collect information to diagnose and fix these issues,
        ensuring a more reliable experience.
      </p>
      <h3>Data We Do Not Collect</h3>
      <p>
        We do not collect any personal information that could be used to
        identify you, such as your:
      </p>
      <ul>
        <li>Name</li>
        <li>Location</li>
        <li>IP address</li>
        <li>Device hardware ID</li>
      </ul>
      <h3>Anonymous Instance Number</h3>
      <p>
        Each time the Castle Coders app is downloaded, that specific app
        instance is assigned an Instance Number. All gameplay and usage data is
        associated with this Number. We cannot use this Number to identify your
        name, location, or IP address, as we do not collect such information.
      </p>
      <h3>How We Use Your Data</h3>
      <p>The data we collect is used to:</p>
      <ul>
        <li>Improve gameplay by adjusting difficulty and enhancing hints.</li>
        <li>Reduce crashes and improve reliability.</li>
      </ul>
      <p>
        We do not use any of the data for advertisement tracking or advertising
        purposes.
      </p>
      <h3>Compliance and Restrictions</h3>
      <p>
        This privacy policy applies strictly to users who use Castle Coders as
        intended and in compliance with our Terms of Service. Unauthorized
        activities, such as hacking, reverse engineering, or modifying the app,
        are strictly prohibited and violate our Terms. We do not consent to or
        support any form of unauthorized access or modification, and we cannot
        ensure the privacy or security of data in cases of non-compliance.
      </p>
      <h3>Requests for Copies of Data</h3>
      <p>
        We are unable to provide a copy of your data. Since we do not collect
        any personal information and all data is tied to an anonymous Instance
        Number, we have no way of knowing which data belongs to a specific
        person. Even if you share personal information with us, we cannot link
        it to any specific data in the game.
      </p>
      <h3>Request for Deletion of Data</h3>
      <p>
        We are unable to delete specific data on request. Because all data is
        tied to an anonymous Instance Number and not personal information, we
        cannot identify or remove data for a particular user. Once collected,
        the data is entirely anonymous.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy or your data, please
        contact us by visiting{" "}
        <a href="https://zaqlo.com/castle-coders/contact-support">
          https://zaqlo.com/castle-coders/contact-support
        </a>
        .
      </p>
    </>
  );
}
